<template>
  <div v-if="round">
    <div class="container-round-single">
      <div class="container">
        <div class="fightback-player" v-if="round.round_file_url">
          <div class="responsive-fightback-embed">
            <iframe ref="iframePlayer"
              width="100%" height="560"
              :src="`${$playerURL}/${round.map}/${round.round_file_url.replace('https://fightback-demos.s3.sa-east-1.amazonaws.com/', '').replace('.json', '')}`"
              scrolling="no" frameborder="0" 
              allow="autoplay; encrypted-media" allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="round-info">
            <h1>{{ $t('UTILITY.round') }} {{round.round_number}}</h1>
            <p>
              <div
                class="team-column"
                v-if="round && round.match && round.match.team_a && round.match.team_b"
              >
                <span v-if="round.match.team_a" class="team">{{round.match.team_a.name}}</span>x
                <span v-if="round.match.team_b" class="team">{{round.match.team_b.name}}</span>
              </div>
              <p v-else v-html="round.match.name || $t('UTILITY.unnamed') "></p>
            </p>

            <div class="round-details">
              {{ $t('UTILITY.winner') }}
              {{ friendlyWinner(round.winner) }} - {{ friendlyReason(round.reason) }}
            </div>

            <div class="round-badges">
              <b-badge size="sm" variant="info">{{round.match.map}}</b-badge>
              <b-badge size="sm" variant="secondary" v-if="round.round_type">
                {{ round.round_type.replace('round_', '') }}
                <span
                  v-if="round.side_trouble && ['round_force_buy', 'round_semi_eco', 'round_eco'].includes(round.round_type)"
                >{{ round.side_trouble.replace('trouble_', '') }}</span>
              </b-badge>
            </div>

            <div class="round-stats">
              <p class="stat" v-if="round.pageviews_count" v-b-tooltip.hover title="visualizações">
                <i class="far fa-eye"></i>
                {{round.pageviews_count}}
              </p>
              <p class="stat" v-if="round.comments_count" v-b-tooltip.hover title="comentários">
                <i class="fas fa-comments"></i>
                {{round.comments_count}}
              </p>

              <br>
              <br>
              <b-btn
                v-b-tooltip.hover
                :title="$t('ROUND_SINGLE.report_round_title')"
                v-b-modal.modalReport
                variant="light"
                size="sm"
                v-if="isLoggedIn"
              >
                {{ $t('ROUND_SINGLE.report_round') }}
              </b-btn>
            </div>
          </div>

          <div class="comments-section">
            <div class="section-title">
              <h5>
                {{ $t('ROUND_SINGLE.comments.title') }}
                <span v-if="round.comments_count">({{round.comments_count}})</span>
              </h5>
            </div>

            <div
              v-if="round.comments.length == 0"
              class="text-muted text-center"
            >
              {{ $t('ROUND_SINGLE.comments.no_comments') }}
            </div>
            <div v-else>
              <div class="comments-container">
                <div v-for="comment in round.comments" :key="comment.id" class="comment-card">
                  <div class="user-avatar">
                    <router-link :to="{ name: 'UserSingle', params: { id: comment.user.id }}">
                      <img :src="comment.user.avatar.url">
                    </router-link>
                  </div>
                  <div class="comment">
                    <div class="comment-body">{{comment.body}}</div>
                    <div class="comment-footer">
                      {{ $t('UTILITY.by') }}
                      <router-link :to="{ name: 'UserSingle', params: { id: comment.user.id }}">
                        <span>{{comment.user.display_name}}</span>
                      </router-link>
                      {{ $t('UTILITY.at') }} {{comment.created_at_friendly}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isLoggedIn">
              <div class="new-comment">
                <form @submit.stop.prevent="postComment">
                  <div class="comment-card post-comment">
                    <div class="user-avatar">
                      <router-link :to="{ name: 'UserSingle', params: { id: currentUser.id }}">
                        <img :src="currentUser.avatar.url">
                      </router-link>
                    </div>
                    <div class="comment">
                      <div class="comment-body">
                        <b-form-textarea
                          id="textarea"
                          v-model="commentText"
                          :placeholder="$t('ROUND_SINGLE.comments.comment_placeholder')"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                  <div class="text-right" style="margin-top: 5px">
                    <b-btn type="submit" variant="secondary">
                      {{ $t('ROUND_SINGLE.comments.submit') }}
                    </b-btn>
                  </div>
                </form>
              </div>
            </div>
            <div v-else class="text-muted text-center">
              {{ $t('ROUND_SINGLE.comments.logged_out_warning') }}
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="action-buttons">
            <router-link :to="{ name: 'MatchSingle', params: { id: round.match.id } }" 
              class="btn btn-outline-dark btn-block" style="margin: 10px 0">
              <i class="fas fa-arrow-left"></i>
              {{ $t('ROUND_SINGLE.actions.back_to_match') }}
            </router-link>

            <b-btn
              v-b-modal.modalCollection
              variant="outline-danger"
              block
              v-if="isLoggedIn"
              class="mb-2"
            >
              <i class="fas fa-folder-plus"></i>
              {{ $t('ROUND_SINGLE.actions.add_to_collection') }}
            </b-btn>

            <b-btn
              v-b-modal.modalShare
              style="cursor:pointer"
              variant="outline-warning"
              block
            >
              <i class="fas fa-share"></i>
              {{ $t('ROUND_SINGLE.actions.share') }}
            </b-btn>
          </div>

          <div
            class="related-rounds"
            v-if="round.related_rounds && round.related_rounds.length > 0"
          >
            <div class="section-title">
              <h5>{{ $t('ROUND_SINGLE.related_rounds') }}</h5>
            </div>

            <MiniRound :round="round" v-for="round in round.related_rounds" :key="round.id"></MiniRound>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modalReport" ref="modalReport" :title="$t('ROUND_SINGLE.report_modal.modal_title')" :hide-footer="true">
      <form @submit.stop.prevent="handleSubmitReport" class="text-center">
        <b-alert show variant="warning">
          <h3>
            {{ $t('ROUND_SINGLE.report_modal.title') }}
          </h3>
          <p>
            {{ $t('ROUND_SINGLE.report_modal.subtitle') }}
          </p>
        </b-alert>

        <b-form-input
          type="text"
          :placeholder="$t('ROUND_SINGLE.report_modal.text_placeholder')"
          v-model="reportText"
        ></b-form-input>

        <b-btn type="submit" variant="primary">
          {{ $t('ROUND_SINGLE.report_modal.submit') }}
        </b-btn>
      </form>
    </b-modal>

    <b-modal id="modalShare" ref="modalShare" class="text-left" :title="$t('ROUND_SINGLE.share_modal.modal_title')" :hide-footer="true">
      <div id="share">
        <a class="facebook" :href="`https://www.facebook.com/share.php?u=${this.shareUrl}`" target="blank">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a class="twitter" :href="`https://twitter.com/intent/tweet?status=${this.shareUrl}`" target="blank">
          <i class="fab fa-twitter"></i>
        </a>
        <a class="googleplus" :href="`https://plus.google.com/share?url=${this.shareUrl}`" target="blank">
          <i class="fab fa-google-plus-g"></i>
        </a>
      </div>

      <h4>
        {{ $t('ROUND_SINGLE.share_modal.title') }}
      </h4>
      
      <b-form-input
        type="text"
        v-model="embedCode"
        readonly
      ></b-form-input>
    </b-modal>

    <b-modal
      id="modalCollection"
      ref="modalCollection"
      :hide-footer="true"
      :hide-header="true"
    >
      <CollectionModal :favorable_id="round.id" favorable_type="MatchRound"></CollectionModal>
    </b-modal>

  </div>
</template>


<script>
import MiniRound from "@/components/MiniRound";
import CollectionModal from "@/components/CollectionModal";
export default {
  name: "RoundSingle",
  computed: {
    embedCode: function() {
      return `<div class="responsive-fightback-embed"><iframe width="639" height="479" src="https://api.fightback.club/embed/${this.round.id}" scrolling="no" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`
    },
    shareUrl: function() {
      return `https://fightback.club/rounds/${this.round.id}`
    }
  },
  data() {
    return {
      round: {
        round_file_url: "",
        team_a: {},
        team_b: {},
        comments: [],
        related_rounds: []
      },
      commentText: "",
      reportText: ""
    };
  },
  methods: {
    initialize() {
      if (this.isLoggedIn)
        this.$http
          .get("collections.json")
          .then(response => (this.collections = response.data));

      this.$http
        .get(`match_rounds/${this.$route.params.id}.json`)
        .then(response => {
          this.round = response.data;
        });
    },

    handleMessage(event) {
      var self = this
      if(event.data.hasOwnProperty('action') && event.data.action == 'roundLoaded') {
        var pieces = event.data.roundUrl.split('/')
        var roundUrl = `${pieces[pieces.length-2]}/${pieces[pieces.length-1]}`
        this.$http.get(`match_rounds/find_by_embed_url.json?url=${roundUrl}`).then(response => {
          self.$router.push({ name: 'RoundSingle', params: { id: response.data.id }})
        })

      }
    },

    handleSubmitReport() {
      this.$http
        .post(`match_rounds/${this.$route.params.id}/report.json`, {
          reportText: this.reportText
        })
        .then(response => {
          this.$notify({
            group: "all",
            text:
              "Estamos verificando esse round. Muito obrigado por reportar!",
            type: "success",
            duration: -1000
          });
          this.reportText = "";
          this.$refs.modalReport.hide();
        });
    },

    postComment() {
      this.$http
        .post(`comments.json`, {
          comment: {
            commentable_id: this.$route.params.id,
            commentable_type: "MatchRound",
            body: this.commentText
          }
        })
        .then(response => {
          this.commentText = "";
          this.initialize();
        });
    },
    
  },
  mounted() {
    this.initialize();
    setTimeout(() => {
      window.addEventListener("message", this.handleMessage)
    }, 500)
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    }
  },
  components: {
    MiniRound,
    CollectionModal
  }
};
// Header
</script>

<style lang="sass" scoped>
.container-round-single
  background: lighten(#0e1327, 3%)

.round-info
  margin: 10px 0
  position: relative

  p
    margin: 0

  h5
    margin: 0
  h6
    margin: 0
  .team-column
    span
      margin-right: 5px
  
  // .round-details
  //   font-size: 12px
  
  .round-badges
    position: absolute
    top: 3px
    right: 5px
    .badge
      margin-left: 5px
  
  .round-stats
    position: absolute
    top: 30px
    right: 5px
    p.stat
      display: inline-block
      margin-left: 10px
      font-weight: bold
      font-size: 24px
      i
        font-weight: normal
        font-size: 18px
  
  // h2
  //   &:not(.badge)
  //     font-weight: bold
  //   .badge
  //     font-size: 12px
  //     position: relative
  //     top: -4px
  // .team-column
  //   display: flex
  //   // justify-content: space-around
  //   div
  //     // font-size: 18px
  //     font-weight: bold
  //     &:first-child
  //       margin-right: 5px
  //     &:last-child
  //       margin-left: 5px            
#share
  width: 100%
  margin: 10px auto
  text-align: center
  a
    width: 50px
    height: 50px
    display: inline-block
    margin: 8px
    border-radius: 50%
    font-size: 24px
    color: #fff
    opacity: 0.75
    transition: opacity 0.15s linear
    &:hover
      opacity: 1
    i
      position: relative
      top: 50%
      transform: translateY(-50%)
  .facebook
    background: #3b5998
  .twitter
    background: #55acee
  .googleplus
    background: #dd4b39

.related-rounds
  margin-top: 20px
.comments-section
  margin-top: 20px
  .comment-card
    display: flex
    align-items: center
    margin: 10px 0

    .user-avatar
      width: 48px
      height: 48px
      min-width: 48px
      overflow: hidden
      // padding: 5px
      margin: 5px
      margin-right: 15px
      img
        width: 100%
        height: auto
        border-radius: 250%
    .comment
      background: #f1f1f1
      flex-grow: 1
      padding: 4px 8px
      border-radius: 4px
      position: relative
      &:before
        content: ''
        height: 0
        width: 0
        position: absolute
        display: block
        border-width: 9px 7px 9px 0
        border-style: solid
        border-color: transparent #f1f1f1
        top: 8px
        left: -6px
      .user-details
        span
          font-weight: bold
      .comment-footer
        font-size: 13px
        a
          font-weight: bold
  .new-comment
    margin-top: 30px

</style>
